:root {
  color-scheme: light only;
}

body {
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
}

#header div:first-child {
  box-shadow: none;
}

.hpehf-centered-content {
  padding: 0 28px !important;
}
